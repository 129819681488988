import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import Myfun from "./util/myfun"
Vue.prototype.myfun = Myfun;




import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.use(ElementUI);

Vue.config.productionTip = false;

Vue.filter('timeStrSubOneSecond', function (timestr: any) {
  // @ts-ignore
  let timestamp : any = new Date(timestr.replace(/-/g,'/')) - 1000;
  let timeObject = new Date(timestamp);
  let y: any = timeObject.getFullYear();
  let m: any = timeObject.getMonth() + 1;
  if (m < 10) {
    m = "0" + m;
  }
  let d: any = timeObject.getDate();
  if (d < 10) {
    d = "0" + d;
  }
  let h: any = timeObject.getHours();
  if (h < 10) {
    h = "0" + h;
  }

  let i: any = timeObject.getMinutes();
  if (i < 10) {
    i = "0" + i;
  }
  let s: any = timeObject.getSeconds();
  if (s < 10) {
    s = "0" + s;
  }
  return y + "-" + m + "-" + d + " " + h + ":" + i + ":" + s;
})


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
