<template>
  <el-container ref="homePage">
    <el-aside width="210px">
      <div class="left-logo">
        <img src="../../static/images/logo.png" />
      </div>
      <div class="left-name">
        <img class="left-name-img" src="../../static/images/pic.png" />
        <div class="name-main">
          <span>{{ userInfo.full_name }}</span>
          <span class="name-guanli">{{ userInfo.uname }}</span>
        </div>
      </div>
      <MyMenu></MyMenu>
    </el-aside>
    <el-container>
      <el-header>
        <div class="fuwushijian">
        </div>
        <el-dropdown trigger="click"  >
          <span class="el-dropdown-link">
            <div class="block">
              <el-avatar :size="40" :src="circleUrl"></el-avatar>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </div>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-user"  v-if="myfun.checkAuthRule('adminUserGetUserList')" @click.native="myfun.myRouterAutoPush('/user/user-list')">用户列表</el-dropdown-item>
            <el-dropdown-item icon="el-icon-s-custom" v-if="myfun.checkAuthRule('adminUserGetRoleList')" @click.native="myfun.myRouterAutoPush('/user/auth-role-list')">角色管理</el-dropdown-item>
            <el-dropdown-item icon="el-icon-s-custom" v-if="myfun.checkAuthRule('adminUserAuthRuleManage')" @click.native="myfun.myRouterAutoPush('/user/auth-rule-manage')">权限管理</el-dropdown-item>
            <el-dropdown-item icon="el-icon-document-copy" v-if="myfun.checkAuthRule('adminSystemGetOperationLog')" @click.native="myfun.myRouterAutoPush('/system/operation-log')">操作日志</el-dropdown-item>
            <el-dropdown-item icon="el-icon-warning-outline" @click.native="myfun.myRouterAutoPush('/user/login')">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

      </el-header>

      <el-main>
        <el-tabs v-model="activeMenuPath"  @tab-remove="closeTab" type="card" @tab-click="handleClick">
          <el-tab-pane :label="item.title" :name="item.path" v-for="(item, index) in openMenus" :key="index" :closable="item.path !== '/'">
            <keep-alive :include="$store.state.keepAlive.join(',')">
              <router-view v-if="showView && item.path === $route.path"  />
            </keep-alive>
          </el-tab-pane>
        </el-tabs>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import MyMenu from "@/components/MyMenu";
import myfun from "@/util/myfun";
export default {
  name: "Default",
  components: {
    MyMenu,
  },
  data() {
    return {
      clientHeight: "",
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      activeMenuName: '',
      activeMenuPath: '',
      userInfo:{
        uname: '载入中...',
        full_name: '载入中...'
      }
    };
  },
  mounted() {
    // 获取浏览器可视区域高度
    this.clientHeight = `${document.documentElement.clientHeight}`;
    //document.body.clientWidth;
    //console.log(self.clientHeight);
    window.onresize = function temp() {
      this.clientHeight = `${document.documentElement.clientHeight}`;
    };

    //默认选中
    if(this.activeMenuPath === '0'){
      this.activeMenuPath = this.openMenus[0].path;
    }

    this.userInfo = myfun.myGetStorage("userInfo");
  },
  watch: {
    // 如果 `clientHeight` 发生改变，这个函数就会运行
    clientHeight: function () {
      this.changeFixed(this.clientHeight);
    },
  },
  methods: {
    changeFixed(clientHeight) {
      //动态修改样式
      // console.log(clientHeight);
      // console.log(this.$refs.homePage.$el.style.height);
      this.$refs.homePage.$el.style.height = clientHeight - 0 + "px";
    },
    handleClick(tab, event) {
      if (this.$route.path === tab.name) {
        // this.$store.commit("setShowView", false);
        // this.$nextTick(() => {
        //   this.$store.commit("setShowView", true);
        // });
      } else {
        let menuObj = this.openMenus.find(item => item.path === tab.name)
        this.myfun.myRouterAutoPush(tab.name, menuObj.query)
      }
    },
    closeTab(tabName){
      let openMenus = this.$store.state.openMenus;
      let openMenusTmp = [];
      let closeIndex = 0;
      let keepAliveTmp = [];
      for (let i in openMenus){
        if(openMenus[i].path !== tabName){
          openMenusTmp.push(openMenus[i]);
          keepAliveTmp.push(openMenus[i].name)
        }else{
          closeIndex = i;
        }
      }

      this.$store.commit('setKeepAlive', keepAliveTmp);
      this.$store.commit('setOpenMenus', openMenusTmp)

      //如果删除的是当前需要切换 如果不是当前tab 无需切换
      if(tabName === this.activeMenuPath){
        let newpath = '';
        let query = {};
        if(typeof(openMenusTmp[closeIndex]) !== 'undefined'){
          this.activeMenuPath = this.openMenus[closeIndex].path;
          newpath = this.openMenus[closeIndex].path;
          query = this.openMenus[closeIndex].query;
        }else if(typeof(openMenusTmp[closeIndex - 1]) !== 'undefined'){
          this.activeMenuPath = this.openMenus[closeIndex-1].path;
          newpath = this.openMenus[closeIndex - 1].path;
          query = this.openMenus[closeIndex - 1].query;
        }else if(typeof(openMenusTmp[closeIndex + 1]) !== 'undefined'){
          this.activeMenuPath = this.openMenus[closeIndex+1].path;
          newpath = this.openMenus[closeIndex + 1].path;
          query = this.openMenus[closeIndex + 1].query;
        }

        this.myfun.myRouterAutoPush(newpath, query)
      }


    },
  },
  computed: {
    showView() {
      return this.$store.state.showView;
    },
    openMenus(){
      this.activeMenuPath = this.$store.state.activePath;
      return this.$store.state.openMenus;
    },
  },
};
</script>

<style>
.el-main{
  background: #eff1f4;
}
.block{
float: left;
margin-top: 10px
}
.fuwushijian{
  font-size: 13px;
  color: #333;
}
.el-header {
  background-color: #fff;
  color: #333;
  line-height: 60px;
  height:60px !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.name-guanli {
  font-size: 12px;
  padding-top: 5px;
}
.name-main {
  font-size: 14px;
  color: #fff;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}
.left-name-img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.left-name {
  padding: 30px 23px;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: url(../../static/images/cover-2-lg.png) center center;
  background-size: cover;
}
.left-logo {
  padding: 16px 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-footer {
  padding: 0;
  margin: 0;
}
.el-aside {
  background: #304156;
  background-size: cover;
}
</style>
