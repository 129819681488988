<template>
  <!--              <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;">-->
  <!--                <el-radio-button :label="false">展开</el-radio-button>-->
  <!--                <el-radio-button :label="true">收起</el-radio-button>-->
  <!--              </el-radio-group>-->

  <el-menu
    :default-active="myMenuActive"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    :unique-opened="true"
    text-color="#ffffff"
    active-text-color="#fff"
    background-color="#304156"
    :router="true"
    :collapse="isCollapse"
  >
    <el-submenu
      v-if="item1.children.length > 0"
      :index="item1.client_route_path"
      v-for="(item1, index1) in menus"
      :key="index1"
    >
      <template slot="title">
        <i :class="item1.icon"></i>
        <span slot="title">{{ item1.name }}</span>
      </template>
      <el-submenu
        v-if="item2.children.length > 0"
        :index="item2.client_route_path"
        v-for="(item2, index2) in item1.children"
        :key="index2"
      >
        <span slot="title" style="color: #f5f5f5">{{ item2.name }}</span>
        <el-submenu
          v-if="item3.children.length > 0"
          :index="item3.client_route_path"
          v-for="(item3, index3) in item2.children"
          :key="index3"
        >
          <span slot="title">{{ item3.name }}</span>
          <el-submenu
            v-if="item4.children.length > 0"
            :index="item4.client_route_path"
            v-for="(item4, index4) in item3.children"
            :key="index4"
          >
            <span slot="title">{{ item4.name }}</span>
            <el-submenu
              v-if="item5.children.length > 0"
              :index="item5.client_route_path"
              v-for="(item5, index5) in item4.children"
              :key="index5"
            >
              <span slot="title">{{ item5.name }}</span>
            </el-submenu>
            <el-menu-item
              v-else
              :index="item5.client_route_path"
              @click.native="saveNavState(item5.client_route_path, item5.id)"
              >{{ item5.name }}</el-menu-item
            >
          </el-submenu>
          <el-menu-item
            v-else
            :index="item4.client_route_path"
            @click.native="saveNavState(item4.client_route_path, item4.id)"
            >{{ item4.name }}</el-menu-item
          >
        </el-submenu>
        <el-menu-item
          v-else
          :index="item3.client_route_path"
          @click.native="saveNavState(item3.client_route_path, item3.id)"
          >{{ item3.name }}</el-menu-item
        >
      </el-submenu>
      <el-menu-item
        v-else
        :index="item2.client_route_path"
        @click.native="saveNavState(item2.client_route_path, item2.id)"
        >{{ item2.name }}</el-menu-item
      >
    </el-submenu>
    <el-menu-item
      v-else
      :index="item1.client_route_path"
      @click.native="saveNavState(item1.client_route_path, item1.id)"
    >
      <template slot="title">
        <i :class="item1.icon"></i>
        <span slot="title">{{ item1.name }}</span>
      </template>
    </el-menu-item>
    <!--    <el-menu-item index="/user/login" v-if="menus.length > 0">-->
    <!--      <template slot="title">-->
    <!--        <i class="el-icon-switch-button"></i>-->
    <!--        <span slot="title">退出</span>-->
    <!--      </template>-->
    <!--    </el-menu-item>-->
  </el-menu>
</template>

<script>
import store from "@/store";

export default {
  name: "MyMenu",
  data() {
    return {
      isCollapse: false,
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    saveNavState(activePath, id) {
      // console.log('ddd',activePath);
      if (this.$store.state.activePath === activePath) {
        // this.$store.commit("setShowView", false);
        // this.$nextTick(() => {
        //   this.$store.commit("setShowView", true);
        // });
      } else {
        this.$store.commit("setActivePath", activePath);
      }
    },
  },
  mounted() {
    this.myfun.request("adminIndexHome.api").then((result) => {
      if (result.code === "1") {
        this.$store.commit(
          "setMenus",
          this.myfun.myGetStorage("userInfo").menus
        );
        this.$store.commit("setPageSizes", result.data.pagesizes);
      }
    });
  },
  computed: {
    myMenuActive() {
      return store.state.myMenuActive;
    },
    menus() {
      return store.state.menus;
    },
  },
};
</script>

<style scoped>
.el-menu-item {
  color: #ffffff !important;
}
.el-submenu .el-menu-item:hover {
  background: #409eff !important;
}
.el-submenu .el-menu-item {
  background: #2d3d51 !important;
}
.el-menu-item.is-active {
  background: #409eff !important;
  color: #ffffff !important;
}
.el-menu i {
  color: #ffffff !important;
}
.el-menu {
  background: transparent;
  border: 0;
  margin-top: 15px;
  border-right-width: 0;
}
</style>
