import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import myfun from "@/util/myfun";
import store from "../store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: 'Default'
    },
  },
  /**************会员模块路由**********************/
  {
    path: "/member/list",
    name: "MemberList",
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/member/MemberList.vue"),
    meta: {
      layout: 'Default'
    },
  },
  {
    path: "/member/membervip-list",
    name: "MembervipList",
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/member/MembervipList.vue"),
    meta: {
      layout: 'Default'
    },
  },
  {
    path: "/member/member-net-search",
    name: "MemberNetSearch",
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/member/MemberNetSearch.vue"),
    meta: {
      layout: 'Default',
      title: '网络查询'
    },
  },
  {
    path: "/member/member-new-pv-search",
    name: "MemberNewPvSearch",
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/member/MemberNewPvSearch.vue"),
    meta: {
      layout: 'Default',
      title: '业绩查询'
    },
  },
  {
    path: "/member/member-settings",
    name: "MemberSettings",
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/member/MemberSettings.vue"),
    meta: {
      layout: 'Default',
      title: '会员设置'
    },
  },
  {
    path: "/member/member-no-repeated-valid",
    name: "MemberNoRepeatedValid",
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/member/MemberNoRepeatedValid.vue"),
    meta: {
      layout: 'Default',
      title: '免重消设置'
    },
  },
  {
    path: "/member/member-distributor-level-manage",
    name: "MemberDistributorLevelManage",
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/member/MemberDistributorLevelManage.vue"),
    meta: {
      layout: 'Default',
      title: '直销商升级设置'
    },
  },

  {
    path: "/member/member-leader-ship-level-manage",
    name: "MemberLeaderShipLevelManage",
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/member/MemberLeaderShipLevelManage.vue"),
    meta: {
      layout: 'Default',
      title: '策略委管理'
    },
  },

  {
    path: "/member/member-business-leader-level-manage",
    name: "MemberBusinessLeaderLevelManage",
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/member/MemberBusinessLeaderLevelManage.vue"),
    meta: {
      layout: 'Default',
      title: '业务领导人管理'
    },
  },

  /**************客服查询模块路由**********************/
  {
    path: "/service/service-dealer-query",
    name: "DealerQuery",
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/service/DealerQuery.vue"),
    meta: {
      layout: 'Default',
      title: '经销商查询'
    },
  },


  {
    path: "/member/member-leader-search",
    name: "MemberLeaderSearch",
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/member/MemberLeaderSearch.vue"),
    meta: {
      layout: 'Default',
      title: '领导人查询'
    },
  },


  {
    path: "/member/member-leader-list",
    name: "MemberLeaderList",
    component: () =>
      import(/* webpackChunkName: "member" */ "../views/member/MemberLeaderList.vue"),
    meta: {
      layout: 'Default',
      title: '销售团队列表'
    },
  },










  {
    path: "/video/list",
    name: "VideoList",
    component: () =>
      import(/* webpackChunkName: "video" */ "../views/video/VideoList.vue"),
    meta: {
      layout: 'Default'
    },
  },
  {
    path: "/video/add",
    name: "VideoAdd",
    component: () =>
      import(/* webpackChunkName: "video" */ "../views/video/VideoAdd.vue"),
    meta: {
      layout: 'Default'
    },
  },
  /**************用户模块路由**********************/
  {
    path: "/user/login",
    name: "UserLogin",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/Login.vue"),
    meta: {
      layout: 'SinglePage',
      title: '用户登陆'
    },
  },
  {
    path: "/user/auth-rule-manage",
    name: "UserLogin",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/AuthRuleManage.vue"),
    meta: {
      layout: 'Default',
      title: '权限管理'
    },
  },
  {
    path: "/user/user-list",
    name: "UserList",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/UserList.vue"),
    meta: {
      layout: 'Default',
      title: '用户列表'
    }
  },
  {
    path: "/user/auth-user-authorize",
    name: "UserAuthorize",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/UserAuthorize.vue"),
    meta: {
      layout: 'Default',
      title: '用户授权',
      menu: '/user/user-list'
    },
  },
  {
    path: "/user/auth-role-list",
    name: "RoleList",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/RoleList.vue"),
    meta: {
      layout: 'Default',
      title: '角色列表'
    }
  },
  {
    path: "/user/auth-role-authorize",
    name: "RoleAuthorize",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/RoleAuthorize.vue"),
    meta: {
      layout: 'Default',
      title: '角色授权',
      menu: '/user/auth-role-list'
    },
  },
  /**************数据模块路由**********************/
  {
    path: "/data/explor-task-list",
    name: "DataExplorTask",
    component: () =>
      import(/* webpackChunkName: "system" */ "../views/data/ExplorTaskList.vue"),
    meta: {
      layout: 'Default',
      title: '导出数据任务列表'
    },
  },
  /**************系统模块路由**********************/
  {
    path: "/system/modify-password",
    name: "SystemModifyPassword",
    component: () =>
      import(/* webpackChunkName: "system" */ "../views/system/ModifyPassword.vue"),
    meta: {
      layout: 'Default',
      title: '修改密码'
    },
  },
  {
    path: "/system/basic-settings",
    name: "SystemBasicSettings",
    component: () =>
      import(/* webpackChunkName: "system" */ "../views/system/BasicSettings.vue"),
    meta: {
      layout: 'Default',
      title: '基本设置'
    },
  },
  {
    path: "/system/operation-log",
    name: "SystemOperationLog",
    component: () =>
      import(/* webpackChunkName: "system" */ "../views/system/OperationLog.vue"),
    meta: {
      layout: 'Default',
      title: '操作日志'
    },
  },
  /**************订单模块路由**********************/
  {
    path: "/order/list",
    name: "OrderList",
    component: () =>
      import(/* webpackChunkName: "order" */ "../views/order/OrderList.vue"),
    meta: {
      layout: 'Default'
    },
  },


  {
    path: "/order/team-order-list",
    name: "TeamOrderList",
    component: () =>
      import(/* webpackChunkName: "order" */ "../views/order/TeamOrderList.vue"),
    meta: {
      layout: 'Default',
      title: '团队订单'
    },
  },
  /**************财务模块路由**********************/
  {
    path: "/finance/apply-list",
    name: "ApplyList",
    component: () =>
      import(/* webpackChunkName: "finance" */ "../views/finance/ApplyList.vue"),
    meta: {
      layout: 'Default'
    },
  },
  {
    path: "/finance/account-balance-list",
    name: "AccountBalanceList",
    component: () =>
      import(/* webpackChunkName: "finance" */ "../views/finance/AccountBalanceList.vue"),
    meta: {
      layout: 'Default'
    },
  },
  {
    path: "/finance/account-log-list",
    name: "AccountLogList",
    component: () =>
      import(/* webpackChunkName: "finance" */ "../views/finance/AccountLogList.vue"),
    meta: {
      layout: 'Default'
    },
  },
  {
    path: "/finance/bank-card-list",
    name: "BankCardList",
    component: () =>
      import(/* webpackChunkName: "finance" */ "../views/finance/BankCardList.vue"),
    meta: {
      layout: 'Default',
      title: '银行卡列表'
    },
  },
  {
    path: "/finance/bank-list",
    name: "BankList",
    component: () =>
      import(/* webpackChunkName: "finance" */ "../views/finance/BankList.vue"),
    meta: {
      layout: 'Default',
      title: '银行列表'
    },
  },
  /**************结算模块路由**********************/
  {
    path: "/settle/settle-list",
    name: "SettleList",
    component: () =>
      import(/* webpackChunkName: "settle" */ "../views/settle/SettleList.vue"),
    meta: {
      layout: 'Default'
    },
  },
  {
    path: "/settle/settle-bonus-list",
    name: "SettleBonusList",
    component: () =>
      import(/* webpackChunkName: "settle" */ "../views/settle/SettleBonusList.vue"),
    meta: {
      layout: 'Default',
      keepAlive: false // 不需要缓存
    },
  },
  {
    path: "/settle/member-agent-log-list",
    name: "SettleMemberAgentLogList",
    component: () =>
      import(/* webpackChunkName: "settle" */ "../views/settle/MemberAgentLogList.vue"),
	  meta: {
	    layout: 'Default'
	  },
  },
  {
    path: "/settle/settle-pv-list",
    name: "SettlePvList",
    component: () =>
      import(/* webpackChunkName: "settle" */ "../views/settle/SettlePvList.vue"),
    meta: {
      layout: 'Default',
      title: '业绩列表',
      keepAlive: false // 不需要缓存
    },
  },
  {
    path: "/settle/member-pv-search",
    name: "MemberPvSearch",
    component: () =>
      import(/* webpackChunkName: "settle" */ "../views/settle/MemberPvSearch.vue"),
    meta: {
      layout: 'Default'
    },
  },
  {
    path: "/settle/member-keep-pv-search",
    name: "MemberKeepPvSearch",
    component: () =>
      import(/* webpackChunkName: "settle" */ "../views/settle/MemberKeepPvSearch.vue"),
    meta: {
      layout: 'Default',
      title: '保留业绩查询'
    },
  },
  {
    path: "/settle/member-repeat-pv-search",
    name: "MemberRepeatPvSearch",
    component: () =>
      import(/* webpackChunkName: "settle" */ "../views/settle/MemberRepeatPvSearch.vue"),
    meta: {
      layout: 'Default',
      title: '重消业绩查询'
    },
  },
  {
    path: "/settle/bonus1-list",
    name: "Bonus1List",
    component: () =>
      import(/* webpackChunkName: "settle" */ "../views/settle/Bonus1List.vue"),
    meta: {
      layout: 'Default',
      title: '推广奖列表'
    },
  },
  {
    path: "/diypage/index",
    name: "DiypageIndex",
    component: () =>
      import(/* webpackChunkName: "settle" */ "../views/diypage/Index.vue"),
    meta: {
      layout: 'Default',
      title: '自定义页面列表'
    },
  },
  {
    path: "/diypage/design",
    name: "DiypageDesign",
    component: () =>
      import(/* webpackChunkName: "settle" */ "../views/diypage/Design.vue"),
    meta: {
      layout: 'SinglePage',
      title: '自定义页面设计'
    },
  },


];

const router = new VueRouter({
  mode: 'history',
  routes,
});


//全局路由前置守卫
router.beforeEach((to, from, next) => {
  //获取当前路由对应的layout
  store.commit('setNowRouterLayout', to.meta.layout);

  //判断是否登录
  const userInfo = myfun.myGetStorage('userInfo');

  if(userInfo){ //登录
    if(store.state.activePath !== to.path){
      store.commit('setActivePath', to.path);
    }
    if(to.meta.layout === 'Default'){
      //判断当前打开菜单是否存在于openMenus中
      let activePath = '';
      for (let i in store.state.openMenus){
        // @ts-ignore
        if(store.state.openMenus[i].path === to.path){
          // @ts-ignore
          activePath = store.state.openMenus[i].path;
          break;
        }
      }

      let openMenus = store.state.openMenus;
      let keepAlive  = store.state.keepAlive;
      if(activePath === ''){ //不存在 则加入到菜单中
        if(userInfo.allAuthRuleClientRoutePathKeyList){
          if(userInfo.allAuthRuleClientRoutePathKeyList[to.path]){
            // @ts-ignore
            openMenus.push({path : to.path, title: userInfo.allAuthRuleClientRoutePathKeyList[to.path].name , name: to.name, query: to.query})
          }
        }else{
          // @ts-ignore
          openMenus.push({path : to.path, title: to.meta.name, name: to.name, query: to.query})
        }

        if(typeof(to.meta.keepAlive) !== 'undefined'){
          if(to.meta.keepAlive){
            // @ts-ignore
            keepAlive.push(to.name);
          }
        }else{
          // @ts-ignore
          keepAlive.push(to.name);
        }
        store.commit('setKeepAlive', keepAlive);
        store.commit('setOpenMenus',openMenus)
        // $store.state.activePath
        // console.log(keepAlive)
      }else{ //更新参数

        // @ts-ignore
        let openMenusTmp: any = openMenus.map(function(obj,index) {
          // console.log(index)
          let rObj = obj;
          // rObj.id=index;//添加id属性
          // @ts-ignore
          if(obj.path === to.path){
            // @ts-ignore
            rObj.query = to.query;//修改属性
          }
          return rObj;
        });
        store.commit('setOpenMenus',openMenusTmp)
      }
    }


  }else{ //未登录
    const login = /^\/user\/login/g;
    const register = /^\/user\/register/g;
    if(!login.test(to.path) && !register.test(to.path)){
      if(from.path === '/user/login'){
        store.commit('setNowRouterLayout', 'SinglePage');  //防止登录页面被嵌套
      }else{
        router.replace('/user/login')
      }
      return;
    }
  }







  next();
})
// 全局路由后置守卫
router.afterEach((to, from) => {

  //设置当前所属导航
  if(to.meta.menu){
    store.commit('setMyMenuActive', to.meta.menu)
  }else{
    store.commit('setMyMenuActive', to.path)
  }



})

export default router;
