<template>
  <div class="home">
    <div class="biaoti" v-loading="head_loadind">
      <div>
<!--        <el-badge-->
<!--          :value="headData.apply_waiting_count || 0"-->
<!--          class="item indexbg"-->
<!--        >-->
<!--          <el-button type="primary" plain size="small" @click="routeTo('/finance/apply-list')"-->
<!--            >待处理提现申请-->
<!--          </el-button>-->
<!--        </el-badge>-->
<!--        <el-badge-->
<!--          :value="headData.unsettle_order_count || 0"-->
<!--          class="item indexbg"-->
<!--          style="margin-left: 30px"-->
<!--        >-->
<!--          <el-button type="danger" plain size="small" @click="routeTo('/order/list')">未结算订单</el-button>-->
<!--        </el-badge>-->
      </div>

      <div class="biaotirigfhr">
        <div class="biaotirigfhr" style="padding-right: 30px">
          <el-progress
            type="circle"
            :percentage="parseFloat(headData.month_pv_rise_rate) || 0"
            :width="50"
            status="success"
          ></el-progress>
          <div class="bobi">
            <span> 上月业绩增长率 </span>
            <br />
            <span style="padding-top: 5px; display: block">
              {{ headData.month_pv_rise_rate || 0 }}%
            </span>
          </div>
        </div>
        <div class="biaotirigfhr">
          <el-progress
            type="circle"
            :percentage="parseFloat(headData.month_bonus_rate) || 0"
            :width="50"
            status="success"
          ></el-progress>
          <div class="bobi">
            <span> 上月拨比 </span>
            <br />
            <span style="padding-top: 5px; display: block">
              {{ headData.month_bonus_rate || 0 }}%
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="yuny">
      <div class="yuny-little">
        <div class="little-left">
          <i class="el-icon-data-line"></i> 运营数据
        </div>
<!--        <el-select-->
<!--          v-model="yunying_country_id"-->
<!--          @change="onSelectChange('yunying_country_id')"-->
<!--          placeholder="请选择"-->
<!--          value="中国区"-->
<!--        >-->
<!--          <el-option-->
<!--            v-for="item in cities"-->
<!--            :key="item.id"-->
<!--            :label="item.name + '-' + item.name_en"-->
<!--            :value="item.id"-->
<!--          >-->
<!--            <span style="float: left">{{ item.name }}</span>-->
<!--            <span style="float: right; color: #8492a6; font-size: 13px">{{-->
<!--              item.name_en-->
<!--            }}</span>-->
<!--          </el-option>-->
<!--        </el-select>-->
      </div>
      <div class="yuny-main" v-loading="yunying_loading">
        <el-tabs :tab-position="tabPosition">
          <el-tab-pane
            class="hone-tabpan"
            v-for="(row, index) in yunYingData.market_data"
            :label="label_name(index)"
          >
            <div class="yuy-mainss">
              <span class="number" v-html="row.daishen"></span>
              <span class="wenzi">普通店长</span>
            </div>
            <div class="yuy-mainss">
              <span class="number" v-html="row.baijin"></span>
              <span class="wenzi">初级店长</span>
            </div>
            <div class="yuy-mainss">
              <span class="number" v-html="row.zuanshi"></span>
              <span class="wenzi">中级店长</span>
            </div>
            <div class="yuy-mainss">
              <span class="number" v-html="row.zhizun"></span>
              <span class="wenzi">高级店长</span>
            </div>
            <div class="yuy-mainss">
              <span class="number" v-html="row.total_pv"></span>
              <span class="wenzi">新增业绩(PV)</span>
            </div>
            <div class="yuy-mainss">
              <span class="number" v-html="row.pay_amount"></span>
              <span class="wenzi">总销售额</span>
            </div>
            <div class="yuy-mainss">
              <span class="number" v-html="row.order_count"></span>
              <span class="wenzi">订单总数</span>
            </div>
          </el-tab-pane>
          <!--          <el-tab-pane label="昨日">配置管理</el-tab-pane>-->
          <!--          <el-tab-pane label="本周">角色管理</el-tab-pane>-->
          <!--          <el-tab-pane label="本月">定时任务补偿</el-tab-pane>-->
        </el-tabs>
      </div>
    </div>
    <div class="tubiao">
      <!-- 图1-->
      <div class="tubuao-list">
        <div class="list-tu-top">
          <div class="little-left">
            <i class="el-icon-data-line"></i> {{ yeJiDesc }}
          </div>
<!--          <el-select-->
<!--            v-model="yeji_country_id"-->
<!--            @change="onSelectChange('yeji_country_id')"-->
<!--            placeholder="请选择"-->
<!--            value="中国区"-->
<!--          >-->
<!--            <el-option-->
<!--              v-for="item in cities"-->
<!--              :key="item.id"-->
<!--              :label="item.name + '-' + item.name_en"-->
<!--              :value="item.id"-->
<!--            >-->
<!--              <span style="float: left">{{ item.name }}</span>-->
<!--              <span style="float: right; color: #8492a6; font-size: 13px">{{-->
<!--                item.name_en-->
<!--              }}</span>-->
<!--            </el-option>-->
<!--          </el-select>-->
        </div>
        <div class="list-tu-bottom echarts" v-loading="yeji_loading">
          <div id="echart" style="width: 100%; height: 100%"></div>
        </div>
      </div>

      <!-- 销售图2-->
      <div class="tubuao-list">
        <div class="list-tu-top">
          <div class="little-left">
            <i class="el-icon-data-line"></i> {{ xiaoShouDesc }}
          </div>
<!--          <el-select-->
<!--            v-model="xiaoshou_country_id"-->
<!--            @change="onSelectChange('xiaoshou_country_id')"-->
<!--            placeholder="请选择"-->
<!--            value="中国区"-->
<!--          >-->
<!--            <el-option-->
<!--              v-for="item in cities"-->
<!--              :key="item.id"-->
<!--              :label="item.name + '-' + item.name_en"-->
<!--              :value="item.id"-->
<!--            >-->
<!--              <span style="float: left">{{ item.name }}</span>-->
<!--              <span style="float: right; color: #8492a6; font-size: 13px">{{-->
<!--                item.name_en-->
<!--              }}</span>-->
<!--            </el-option>-->
<!--          </el-select>-->
        </div>
        <div class="list-tu-bottom echarts" v-loading="xiaoshou_loading">
          <div id="echart2" style="width: 100%; height: 100%"></div>
        </div>
      </div>
    </div>

    <div class="paihangbang">
      <div class="paihangbang-top">
        <div class="little-left">
          <i class="el-icon-data-line"></i>{{ leaderDesc }}
        </div>
<!--        <el-select-->
<!--          v-model="formData.leader_country_id"-->
<!--          @change="onSelectChange('leader_country_id')"-->
<!--          placeholder="请选择"-->
<!--          value="中国区"-->
<!--        >-->
<!--          <el-option-->
<!--            v-for="item in cities"-->
<!--            :key="item.id"-->
<!--            :label="item.name + '-' + item.name_en"-->
<!--            :value="item.id"-->
<!--          >-->
<!--            <span style="float: left">{{ item.name }}</span>-->
<!--            <span style="float: right; color: #8492a6; font-size: 13px">{{-->
<!--              item.name_en-->
<!--            }}</span>-->
<!--          </el-option>-->
<!--        </el-select>-->
      </div>
      <div class="paihangbang-bottom">
        <el-table
          v-loading="leader_loading"
          :data="leaderData"
          stripe
          style="width: 100%"
          max-height="600"
        >
          <el-table-column  align="center"
                            header-align="center" prop="ranking" label="排名"> </el-table-column>
          <el-table-column  align="center"
                            header-align="center" prop="uname" label="会员编号"> </el-table-column>
          <el-table-column  align="center"
                            header-align="center" prop="full_name" label="姓名"> </el-table-column>
          <el-table-column  align="center"
                            header-align="center" prop="team_member_count" label="团队总人数"></el-table-column>
          <el-table-column  align="center"
                            header-align="center" prop="tjwpv" label="推荐网业绩PV"></el-table-column>
          <el-table-column  align="center"
                            header-align="center" prop="azwpv" label="安置网业绩PV"></el-table-column>
          <el-table-column  align="center"
                            header-align="center" prop="team_pay_amount" label="团队销售额"></el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
                  background
            style="margin-top: 1rem"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="formData.page"
            :page-sizes="pageSizs"
            :page-size="formData.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="formData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
// @ is an alias to /src
export default {
  name: "Home",
  data() {
    return {
      head_loadind: false,
      headData: {},

      yunying_loading: false,
      yunying_country_id: "",
      yunYingData: {},

      xiaoshou_loading: false,
      xiaoshou_country_id: "",
      xiaoShouDesc: "年度销售额曲线图",
      xiaoShouData: {},

      yeji_loading: false,
      yeji_country_id: "",
      yeJiDesc: "年度业绩柱形图",
      yeJiData: {},

      leader_loading: true,
      leader_country_id: "",
      leaderDesc: "月度领导人业绩排行榜",
      leaderData: [],

      formData: {
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        searchField: "1",
        leader_country_id: "",
      },

      tabPosition: "right",
      myChart: {},
      myChart2: {},
      tableData: [
        {
          num: "1",
          id: "0858808810",
          name: "张三viiva",
          PV: "10000.00",
          rs: "100",
          xse: "¥500.23",
        },
      ],
      cities: [], //国家数组
      value: "中国区-Chinese",
    };
  },
  mounted() {
    let _this = this;
    window.onresize = function () {
      _this.myChart.resize();
      _this.myChart2.resize();
    };
  },
  methods: {
    /**
     * 下拉菜单改变,请求数据
     */
    onSelectChange(target) {
      if (target === "yunying_country_id") {
        this.getYunYingData();
      } else if (target === "yeji_country_id") {
        this.getYeJiData();
      } else if (target === "xiaoshou_country_id") {
        this.getXiaoShouData();
      } else if (target === "leader_country_id") {
        this.getLeaderData(1);//切国家从第一页开始
      }
    },

    /**
     * 业绩柱形图
     */
    yeJiEchart() {
      this.myChart = echarts.init(document.getElementById("echart"));
      this.myChart.setOption({
        color: ["#409EFF"],
        title: {
          // text: "ECharts",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["业绩PV"],
        },
        xAxis: {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          data: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        grid: {
          left: "1%",
          right: "1%",
          bottom: "3%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "业绩PV",
            type: "bar",
            data: this.yeJiData,
            // data:[554, 200, 306, 100, 110, 520, 555, 240, 1000, 170, 100, 210],
          },
        ],
      });
    },

    /**
     * 销售柱形图
     */
    xiaoShouEchart() {
      this.myChart2 = echarts.init(document.getElementById("echart2"));
      this.myChart2.setOption({
        color: ["#F56C6C", "#E6A23C"],
        title: {
          // text: "ECharts",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["销售额$", "奖金拨出$"],
        },
        xAxis: {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          data: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        grid: {
          left: "1%",
          right: "1%",
          bottom: "3%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "销售额$",
            type: "bar",
            data: this.xiaoShouData["pay_amount"],
          },
          {
            name: "奖金拨出$",
            type: "line",
            data: this.xiaoShouData["bonus"],
          },
        ],
      });
    },

    /**
     * 获取默认国家的数据
     */
    initData() {
      this.getHeadData();
      this.getYunYingData();
      this.getYeJiData();
      this.getXiaoShouData();
      this.getLeaderData();
    },
    /**
     * 头部所有国家统计数据
     */
    getHeadData() {
      this.head_loadind = true;
      this.myfun.request("adminIndexHeadData.api").then((result) => {
          if (result.code === "1") {
            this.headData = result.data;
          }
          this.head_loadind = false;
        });
    },
    /**
     * 运营数据
     */
    getYunYingData() {
      this.yunying_loading = true;
      this.myfun.request(
          "adminIndexYunYingData.api",
          { country_id: this.yunying_country_id },
          "post"
        )
        .then((result) => {
          if (result.code === "1") {
            this.yunYingData = result.data;
          }
          this.yunying_loading = false;
        });
    },
    /**
     * 业绩数据
     */
    getYeJiData() {
      this.yeji_loading = true;
      this.myfun.request(
          "adminIndexYeJiData.api",
          { country_id: this.yeji_country_id },
          "post"
        )
        .then((result) => {
          if (result.code === "1") {
            this.yeJiDesc = result.data.desc;
            this.yeJiData = result.data.data;
            this.yeJiEchart(); //刷新业绩柱形图
          }
          this.yeji_loading = false;
        });
    },
    /**
     * 销售数据
     */
    getXiaoShouData() {
      this.xiaoshou_loading = true;
      this.myfun.request(
          "adminIndexXiaoShouData.api",
          { country_id: this.xiaoshou_country_id },
          "post"
        )
        .then((result) => {
          if (result.code === "1") {
            this.xiaoShouDesc = result.data.desc;
            this.xiaoShouData = result.data;
            this.xiaoShouEchart(); //刷新销售柱形图
          }
          this.xiaoshou_loading = false;
        });
    },
    /**
     * 领导人数据
     */
    getLeaderData(page) {
      this.getList(page);
    },

    /**
     * 每页数据变坏
     */
    handleSizeChange(val) {
      this.formData.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.formData.page = val;
      // this.getList();
      this.getLeaderData();
    },
    /**
     * 获取列表数据
     */
    getList(page = 0) {
      // this.leader_loading =true;
      // if (page > 0) {
      //   this.formData.page = page;
      // }
      // this.myfun.request("adminIndexLeaderData.api", this.formData)
      //   .then((result) => {
      //     this.leaderData = [];//请求返回再清除数据,防止页面抖动
      //     if (result.code === "1") {
      //       this.leaderData = result.data.list;
      //       this.leaderDesc = result.data.desc;
      //     }
      //     this.leader_loading = false;
      //     this.formData.total = parseInt(result.data.total);
      //   });
    },
    /**
     * 页面跳转
     */
    routeTo(path){
      this.$router.push(path)
    }
  },
  created() {
    this.initData();
  },
  computed: {
    /**
     * 运营数据,标签名称
     * @returns {function(*): string}
     */
    label_name() {
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      return (idx) => {
        let name = "标签" + idx;
        if (idx === 0) {
          name = "今日";
        } else if (idx === 1) {
          name = "昨日";
        } else if (idx === 2) {
          name = "本周";
        } else if (idx === 3) {
          name = "本月";
        }
        return name;
      };
    },
    pageSizs() {
      return this.$store.state.pageSizes;
    },
  },
};
</script>

<style>
.paihangbang-bottom {
  padding: 10px;
  height: 100%;
}

.paihangbang-top {
  padding: 0 10px 20px 10px;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paihangbang {
  padding: 20px 0;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 500px;
}

.list-tu-bottom {
  padding: 20px 10px;
  height: 100%;
}

.list-tu-top {
  padding: 20px 10px;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tubuao-list {
  width: 49.3%;
  height: 400px;
  border-radius: 6px;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.tubiao {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bobi {
  font-size: 12px;
  color: #666;
  padding-left: 10px;
}

.biaotirigfhr {
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexbg {
  margin-right: 20px;
}

.biaoti {
  display: flex;
  justify-content: space-between;
  padding: 0 0 20px 0;
  align-items: center;
}

.wenzi {
  padding-top: 10px;
  color: #999;
  font-size: 13px;
  font-weight: 600;
}

.number {
  font-size: 30px;
  color: #000;
}

.yuy-mainss {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.hone-tabpan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 160px;
  text-align: center;
}

.yuny-main {
  padding: 20px 0 0 0;
  height: 170px;
}

.little-left {
  font-size: 18px;
}

.yuny-little {
  display: flex;
  height: 40px;
  border-bottom: 1px solid #f5f5f5;

  justify-content: space-between;
  line-height: 40px;
  align-items: center;
  padding: 10px 0;
}

.yuny {
  padding: 10px;
  background: #fff;
  border-radius: 6px;
  height: 250px;
  display: flex;
  flex-direction: column;
}

.home {
  display: flex;
  flex-direction: column;
}
</style>
